/* Tokens */

%display-2 {
  font-size: var(--font-size-4xl);
  font-family: var(--font-apris);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-display);
}

%display-3 {
  font-family: var(--font-apris);
  font-size: var(--font-size-3xl);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-display);
}

%display-4 {
  font-family: var(--font-apris);
  font-size: var(--font-size-2xl-display);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-display-small);
}

%display-5 {
  font-family: var(--font-apris);
  font-size: var(--font-size-xl-display);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-display);
}

%h4 {
  font-family: var(--font-goodsans);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-display);
}

/* specific classes */

.hero-title {
  @extend %display-2;
  color: var(--color-white);
}

.blok-big-title {
  @extend %display-3;
  line-height: 1.25;
  @media (--large) {
    line-height: 1.167;
  }
}

.blok-title {
  @extend %display-4;
  text-wrap: balance;
  line-height: 1.429;
  @media (--large) {
    line-height: 1.222;
  }
}

.blok-subtitle {
  @extend %display-5;
  line-height: 1.455;
  @media (--large) {
    line-height: 1.2;
  }
}
