.modal {
  position: fixed;
  top: 0;
  right: 0;
  background: var(--color-modal);
  color: var(--color-white);
  z-index: 999;
  height: 100vh;
  padding: var(--page-inset);
  animation: modalIn 200ms both;
  width: min(520px, 84vw);
  display: flex;
  flex-direction: column;
  gap: var(--section-spacing);
}

@keyframes modalIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
